import { ProductType } from "../types/products/products-type";

export const products : Array<ProductType> = [
    {
      id: 5,
      name: 'cheeseless pizza',
      category: 'pizza',
      image:
        'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672324456/NetflixApp/cheeseless_pizza_bkuecz.jpg',
      price: '$200',
    },
    {
      id: 6,
      name: 'Supreme Pizza',
      category: 'pizza',
      image:
        'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672324455/NetflixApp/Supreme_Pizza_jtvkj8.jpg',
      price: '$300',
    },
    {
      id: 7,
      name: 'pepperoni pizza',
      category: 'pizza',
      image:
        'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672324456/NetflixApp/pepperoni_pizza_axwdat.jpg',
      price: '$100',
    },
    {
      id: 8,
      name: 'Cheese Pizza',
      category: 'pizza',
      image:
        'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672324456/NetflixApp/chinese_pizza_kgnbo1.jpg',
      price: '$140',
    },
    {
      id: 8,
      name: 'African Salad',
      category: 'salad',
      image:
        'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672323870/NetflixApp/African_Salad_s7vgxn.png',
      price: '$140',
    },
    {
      id: 9,
      name: 'Kale Salad',
      category: 'salad',
      image:
        'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672323860/NetflixApp/kale_salad_ogune3.jpg',
      price: '$300',
    },
    {
      id: 10,
      name: 'Ceasar Salad',
      category: 'salad',
      image:
        'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672323859/NetflixApp/caesar-salad-with-tuna-30216-1_noflfd.jpg',
      price: '$300',
    },
    {
      id: 11,
      name: 'Loaded Salad',
      category: 'salad',
      image:
        'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672323860/NetflixApp/loaded_salad_qq5jup.jpg',
      price: '$200',
    },
    {
      id: 12,
      name: 'chiken_shawarma',
      category: 'chicken',
      image:
        'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672323737/NetflixApp/chiken_shawarma_c0ysna.jpg',
      price: '$100',
    },
    {
      id: 13,
      name: 'Wings',
      category: 'chicken',
      image:
        'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672322922/NetflixApp/chicken-wings_lv3zc9.jpg',
      price: '$100',
    },
    {
      id: 14,
      name: 'Roasted Chicken',
      category: 'chicken',
      image:
        'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672322922/NetflixApp/roast-chicken_nrc6r8.jpg',
      price: '$300',
    },
    {
      id: 15,
      name: 'Chicken Tenders',
      category: 'chicken',
      image:
        'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672322923/NetflixApp/chicken_tenders_quel6y.jpg',
      price: '$100',
    },
  
  ];

  export const categories : Array<string>= [ 
    ...new Set(
      products.map( (product : ProductType) => product.category)
    )
  ]
