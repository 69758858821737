import { FC } from "react";
import { Section } from "../../standard/Section";
import { Container } from "../../standard/Container";
import { SectionName } from "../../theme/SectionName";
import { ProductGrid } from "../../standard/ProductGrid";
import { useProducts } from "../../../hooks/use-products";
import { OurMealFilter } from "./OurMealFilter";
import { OurMealCard } from "./OurMealCard";

export const OurMeal : FC = () => {

    const { filteredProducts, categories, setCategory, category } = useProducts()

    return (
        <Section>
            <Container>
                <SectionName>Our Meal</SectionName>
                <OurMealFilter setCategory={setCategory} categories={categories} category={category}/>
                <ProductGrid products={filteredProducts} card={OurMealCard}/>
            </Container>
        </Section>
    )
}