import { FC } from 'react'
import styled from 'styled-components'
import { TrendingCategoryType } from '../../../types/products/trending-categories-type'

const CardTag = styled.div`
    width: 100%;
    height: 50px;    
    padding: 0.5rem 1.25rem;
    box-sizing: border-box;    
    cursor: pointer;
    transition-duration: 300ms;
    &:hover{
        transform: scale(1.1);        
    }
    img {
        width: 100%;
        height: 3rem;
        object-fit: contain;
        object-position: center center;
        box-shadow: var(--box-shadow-standard);
        border-radius: var(--border-radius-standard);       
    }
`

export const CategoryCard : FC<{ category: TrendingCategoryType}> = ({ category }) => {
    return (
        <CardTag>
            <img 
                src={category.image} 
                alt={category.name}
                title={category.name}
            />
        </CardTag>
    )
}