import { TopNav } from "./components/sections/top-nav/TopNav"
import { MainSlider } from "./components/sections/slider/MainSlider"
import { DeliveryApp } from "./components/sections/delivery-app/DeliveryApp"
import { TopPics } from "./components/sections/top-picks/TopPicks"
import { OurMeal } from "./components/sections/our-meal/OurMeal"
import { TrendingCategories } from "./components/sections/trending-categories/TrendingCategories"
import { Footer } from "./components/sections/footer/Footer"

export const App = () => {
  return (
    <>
      <TopNav />
      <MainSlider />
      <DeliveryApp />
      <TopPics />
      <OurMeal />
      <TrendingCategories />
      <Footer />
    </>
  )
}