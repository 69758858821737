import { FC, useState } from "react";

import styled from 'styled-components'

import { Container } from "../../standard/Container";
import { SideNavToggle } from "./SideNavToggle";
import { Logo } from "./Logo";
import  { FreeDeliveryIcon } from './FreeDeliveryIcon'
import { SearchBar } from "./SearchBar";
import { CartButton } from "./cartButton";
import { SideNav } from "./SideNav";
import { devices } from "../../../devices";

const TopNavContainer = styled.div`
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;    
`

const LogoContainer = styled.div`
        display: flex;
        gap: 10px;
        justify-content: flex-start;
        align-items: center;
    
`

const SearchBarContainer = styled.div`
    display: none;
    @media(${devices.md}){
        display: inherit;
    }   
`

const CartButtonContainer = styled.div`
    display: none;
    @media(${devices.sm}){
        display: inherit;
    }    
`
export const TopNav : FC = () => {

    const [isSideNavOpen, setIsSideNavOpen ] = useState<boolean>(false)
    const closeSideNav = () => { setIsSideNavOpen(false)}

    return (
        <Container>
            <TopNavContainer>
                <LogoContainer>
                    <SideNavToggle onClick={ () => { setIsSideNavOpen(true) }}/>
                    <Logo/>
                    <FreeDeliveryIcon/>
                </LogoContainer>
                <SearchBarContainer>
                    <SearchBar />
                </SearchBarContainer>
                <CartButtonContainer>
                    <CartButton />
                </CartButtonContainer>
                <SideNav 
                    isOpen={isSideNavOpen }
                    onClose={closeSideNav}
                />
            </TopNavContainer>
        </Container>
    )
}