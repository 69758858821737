import { FC } from "react";
import styled from 'styled-components'
import { ProductType } from "../../../types/products/products-type";
import { FaArrowRight } from "react-icons/fa";

const CardTag = styled.div`
    width: 100%;
    cursor: pointer;
    transition-duration: 300ms;
    &:hover{
        transform: scale(1.05);
    }
    img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        object-position: center center;
        border-radius: var(--border-radius-standard);
    }
`

const TitleTag = styled.span`
    font-weight: 700;
    margin-top: 10px;
    margin-left: 10px;
`
const PriceTag = styled.span`
    --dimension: 55px;
    display: flex;
    width: var(--dimension);
    height: var(--dimension);
    border-radius: 50%;
    background-color: var(--primary);
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 900;
    position: absolute;
    bottom: -10px;
    right: 10px;
    border: 7px solid white;

`

type CardProp = {
    product: ProductType
}
export const OurMealCard : FC<CardProp> = ({ product }) => {
    return (
        <CardTag>
            <img src={product.image} alt={product.name} title={product.name} />
            <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative'}}>
                <TitleTag>{product.name}</TitleTag>
                <PriceTag>{product.price}</PriceTag>
            </div>
            <div style={{ cursor: 'pointer', paddingLeft: '10px', paddingTop: '10px', paddingBottom: '10px', display: 'flex', gap: '10px', alignItems: 'center'}}>
                <span>View more</span>
                <FaArrowRight size={20} />
            </div>
        </CardTag>
    )
}