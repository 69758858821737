import { NavigationItemType } from "../types/navigation/navigation-items.type";

import { 
    FaUser, 
    FaTruck,
    FaHeart,
    FaWallet,
    FaQuestionCircle
} from "react-icons/fa";

export const useNavigationItems = () => {
    const navigationItems : Array<NavigationItemType> = [
        { icon: FaUser, text: 'My Account' },
        { icon: FaTruck, text: 'Delivery' },
        { icon: FaHeart, text: 'My Favourite' },
        { icon: FaWallet, text: 'My Wallet' },
        { icon: FaQuestionCircle, text: 'Help' },        
    ]
    return {
        navigationItems
    }    
}