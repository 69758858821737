import { ComponentPropsWithRef, FC } from "react"
import styled from 'styled-components'

type TextInputProp = ComponentPropsWithRef<"input"> & {
    full?: boolean    
}

const InputTag = styled.input<TextInputProp>`
    width: ${props => props.full ? '100%' : 'auto'};
    border-radius: var(--border-radius-standard);
    outline: none;
    border: 1px solid #ddd;
    padding: 0px 10px;
`

export const TextInput : FC<TextInputProp> = ({ ...props }) => {
    return <InputTag 
            type="text" 
            {...props}            
            />
}