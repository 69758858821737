import { FC, useState } from "react";

import styled from 'styled-components'

import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { SliderImageType } from "../../types/slider/slider-images-type";

const SliderContainer = styled.div`
    width: 100%;
    height: 600px;
`

const Slide = styled.div<{ imageUrl: string}>`
    width: 100%;
    height: 500px;
    background-position: center center;
    background-size: cover;
    background-image: url(${props => props.imageUrl});
    border-radius: var(--border-radius-standard);
    position: relative;
    transition-duration: 300ms;
`

const shiftSlideButton = styled.span`
    background-color: var(--primary);
    color: white;
    font-weight: bold;
    display: inline-block;
    padding: 5px;
    border-radius: var(--border-radius-max);
    position: absolute;
    top:50%;
    cursor: pointer;
`
const LeftButton = styled(shiftSlideButton)`
    left: 15px;
`

const RightButton = styled(shiftSlideButton)`
    right: 15px;
`

const SlideDotsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
`

const SlideDot = styled.span<{ selected: boolean}>`
    --dimension: 15px;
    display: inline-block;
    height: var(--dimension);
    width: var(--dimension);
    background-color: ${ props => props.selected ? "var(--primary)" : "var(--black)"};
    border-radius: 50%;
    cursor: pointer;
`

interface SlideDotsProps {
    amount: number,
    currentIndex: number,
    goToSlide: (index: number) => void
}

export const SlideDots : FC<SlideDotsProps> = ({ amount, currentIndex, goToSlide }) => {
    return (
        <SlideDotsContainer>
            {
                Array.from({ length: amount}, (_, index) => (<SlideDot onClick={() => goToSlide(index)} selected={index === currentIndex} key={index}/>))
            }
        </SlideDotsContainer>
    )
}

interface SliderProps {
    images: Array<SliderImageType>
}
export const Slider : FC<SliderProps> = ({ images }) => {

    const [ currentImageIndex, setCurrentImageIndex ] = useState<number>(0)

    const goToSlide = (target: number) => {
        const lastSlide : number = images.length - 1
        const firstSlide : number = 0
        setCurrentImageIndex(target < firstSlide ? lastSlide : (target > lastSlide ? firstSlide : target))
    }

    const goToPrevSlide = () : void => goToSlide(currentImageIndex - 1)
    const goToNextSlide = () : void => goToSlide(currentImageIndex + 1)

    return (
        <SliderContainer>
            <Slide imageUrl={images[currentImageIndex].url}>
                <LeftButton onClick={goToPrevSlide}><FaChevronLeft size={25}/></LeftButton>
                <RightButton onClick={goToNextSlide}><FaChevronRight size={25}/></RightButton>
            </Slide>
            <SlideDots amount={images.length} currentIndex={currentImageIndex} goToSlide={goToSlide}/>
        </SliderContainer>
    )
}