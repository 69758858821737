import { FC, MouseEventHandler } from "react";

import styled from 'styled-components'

import { FaBars } from 'react-icons/fa'

const SideNavToggleTag = styled.span`
    cursor: pointer;
`

interface PropType {
    onClick: MouseEventHandler<HTMLElement>
}

export const SideNavToggle : FC<PropType> = ({ onClick }) => (<SideNavToggleTag onClick={onClick}><FaBars size={25} /></SideNavToggleTag>)