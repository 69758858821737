import { FC } from 'react'
import { IconType } from 'react-icons/lib'

import styled from 'styled-components'

const TileTag = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
`

interface TileProp {
    icon: IconType,
    text: string
}

export const DrawerTile : FC<TileProp> = ({ icon, text }) => {
    return (
        <TileTag>
            { icon({ size: 20 })}
            { text }
        </TileTag>
    )
}