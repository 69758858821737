import { FC } from "react"
import { SectionName } from "../../theme/SectionName"
import { Section } from "../../standard/Section"
import { InfiniteSlider } from "../../standard/InfiniteSlider"
import { useTopPicks } from "../../../hooks/use-top-picks"
import { TopPicksItem } from "./TopPicksItem"

export const TopPics : FC = () => {

    const { topPicks } = useTopPicks()

    return (
        <Section paddingX='10px'>
            <SectionName>Top Picks</SectionName>
            <InfiniteSlider 
                slideItems={topPicks}
                slideLayout={TopPicksItem}
            />
        </Section>
    )
}