import { FC } from "react";
import styled, { css } from 'styled-components'
import { Button } from "../../theme/Button";

const Card = styled.div`
    height: 200px;
    width: 100%;
    border-radius: var(--border-radius-standard);
    position: relative;
    cursor: pointer;
    img {
        max-width: 100%;
        width: 100%;
        height: 200px;
        object-fit: cover;
        object-position: center center;
        border-radius: var(--border-radius-standard);
    }
    &:hover{
        transform: scale(1.05);
    }
`

const CardInnerContainer = styled.div`
    height: 100%;
    width: 100%;
    background-color: rgb(0 0 0 / 0.5);
    position: absolute;
    border-radius: var(--border-radius-standard);
`

const textTags = css`
    color: white;
    display: block;
    margin-left: 10px;
    margin-top: 10px;
`
const TitleTag = styled.span`
    ${textTags};
    font-weight: 700;
`
const PriceTag = styled.span`
    ${textTags};
  `

const BuyNowButton = styled(Button)`
    position: absolute;
    bottom: 10px;
    left: 10px;
`
 
type TopPicksItemType = {
    slideData: any
}
export const TopPicksItem : FC<TopPicksItemType> = ({ slideData }) => {
    return (
        <Card>
            <CardInnerContainer>
                <TitleTag>{slideData.title}</TitleTag>
                <PriceTag>{slideData.price}</PriceTag>
                <BuyNowButton variant="outline-dotted-white">Add to cart</BuyNowButton>
            </CardInnerContainer>
            <img src={slideData.img} alt="Top Picks" title={slideData.title} />
        </Card>
    )
}