export const deviceWidth : Record<string, number> = {
    sm: 601,
    md: 993,
    lg: 1201,
    xl: 1501 
}
export const devices : Record<string, string> = {
    xs: `max-width: ${deviceWidth.sm - 1}px`,
    sm: `min-width: ${deviceWidth.sm}px`,
    md: `min-width: ${deviceWidth.md}px`,
    lg: `min-width: ${deviceWidth.lg}px`,
    xl: `min-width: ${deviceWidth.xl}px`
}
