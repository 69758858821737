import { FC } from 'react'

import styled from 'styled-components'
import { FaShoppingCart } from 'react-icons/fa'

const CartButtonTag = styled.span`
    display: inline-block;
    border: 1px solid var(--black);
    background-color: var(--primary);
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
    border-radius: var(--border-radius-max);
    font-weight: bold;
`

export const CartButton : FC = () => (
    <CartButtonTag>
        <FaShoppingCart size={25} />
        Cart
    </CartButtonTag>
)