import { FC } from "react";
import styled from 'styled-components'
import { devices } from "../../../devices";

import { TrendingCategoryType } from "../../../types/products/trending-categories-type";

const GridTag = styled.div`
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media(${devices.sm}) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media(${devices.lg}) {
        grid-template-columns: repeat(4, 1fr);
    }    
    @media(${devices.xl}) {
        grid-template-columns: repeat(6, 1fr);
    }        
    gap: 20px;
`

export const CategoriesGrid : FC<{
    categories: Array<TrendingCategoryType>,
    categoryCard : FC<{ category: TrendingCategoryType}>
}> = ({
    categories,
    categoryCard
}) => {
    return (
        <GridTag>
            {
                categories.map(
                    (category: TrendingCategoryType) => categoryCard({category})
                )
            }
        </GridTag>
    )
}