import { FC } from "react"
import { Section } from "../../standard/Section"
import { SectionName } from "../../theme/SectionName"
import { Container } from "../../standard/Container"
import { CategoriesGrid } from "./CategoriesGrid"
import { useTrendingCategories } from "../../../hooks/use-trending-categories"
import { CategoryCard } from "./CategoryCard"

export const TrendingCategories :FC = () => {

    const { trendingCategories } = useTrendingCategories()

    return (
        <Section>
            <SectionName>Trending Categories</SectionName>
            <Container>
                <CategoriesGrid 
                    categories={trendingCategories}
                    categoryCard={CategoryCard}
                />
            </Container>
        </Section>
    )
}