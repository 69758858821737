import { useMemo, useState } from "react";
import { ProductType } from "../types/products/products-type";
import { products, categories } from "../data/products";


export const useProducts = () => {

    const allProductsCategory : string = 'all'

    const [category, setCategory ] = useState<string>(allProductsCategory)

    const filteredProducts = useMemo(
        () : Array<ProductType>=> products.filter(
            (product : ProductType) => category === allProductsCategory || category === product.category,
        ),
        [category]
    )
    
    return {
        filteredProducts,
        categories: [
            allProductsCategory,
            ...categories
        ],
        category,
        setCategory
    }
      
}