import { FC, Dispatch, SetStateAction } from "react";
import styled from "styled-components";

type FilterProp = {
    categories: Array<string>,
    category: string,
    setCategory: Dispatch<SetStateAction<string>>
}

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-bottom: 20px;
`

const Tag = styled.span<{ selected: boolean}>`
    background-color: ${props => props.selected ? 'white' : 'var(--primary)' };
    color: ${props => props.selected  ? 'var(--primary)' : 'white' };
    padding: 5px 10px;
    font-weight: 700;
    border: 1px solid var(--primary);
    text-transform: capitalize;
    border-radius: var(--border-radius-standard);
    cursor: pointer;
    &:hover {
        background-color: white;
        color: var(--primary);        
    }
`
export const OurMealFilter : FC<FilterProp> = ({ categories, setCategory, category}) => {
    return (
        <Wrapper>
            {
                categories.map(
                    (categoriesEntry : string) => (
                        <Tag 
                            onClick={() => setCategory(categoriesEntry)}
                            selected={categoriesEntry === category}
                        >
                            {categoriesEntry}
                        </Tag>
                    )
                )
            }
        </Wrapper>
    )
}