import { FC } from "react";
import styled from "styled-components";
import { Button } from "../../theme/Button";
import { TextInput } from "../../theme/TextInput";
import { devices } from "../../../devices";

const NewsLetterContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    @media(${devices.md}){
        grid-template-columns: 1fr 2fr;
        grid-template-rows: minmax(150px, auto);
    }
    .message { 
        border-bottom: 1px solid white;
        text-align: center;
        @media(${devices.md}){
            text-align: left;
        }
    }
    .newsletter { 
        align-self: center;
        justify-self: center;
        @media(${devices.md}){
            justify-self: right;
        }
        padding: 20px;
    }

`
export const NewsLetter : FC = () => {
    return (
        <NewsLetterContainer>
            <div className="message">
                <p>Need advice on how to improve your flow?</p>
                <p>Sign up to join our newsletter and stay up to date.</p>
            </div>
            <div className="newsletter">
                <div style={{ display: 'flex', gap: '10px' }}>
                    <TextInput full placeholder="your email"/>
                    <Button variant="secondary">Notify me</Button>
                </div>
                <p>We are concerned about the security of your data, <br/>read our <span style={{ color: 'var(--secondary)'}}>Privacy Policy</span></p>
            </div>
        </NewsLetterContainer>
    )
}