import { FC } from 'react'
import styled from 'styled-components'

type VariantsAvailable = 'primary' | 'outline-primary' | 'dark' | 'outline-dotted-white' | 'secondary'

const ButtonTag = styled.button<{ variant: VariantsAvailable }>`
    font-weight: 700;
    font-size: 1rem;
    padding: 10px 20px;
    text-wrap: nowrap;
    background-color: ${ props => {
        switch(props.variant){
            case 'dark':
                return "var(--black)"
            case 'outline-primary':
            case 'outline-dotted-white':
                return "transparent"
            default:
                return `var(--${props.variant}, --primary)`
            }   
        }};
    color: ${props => props.variant === 'outline-primary' ? 'var(--primary)' : 'white'};
    border-radius: var(--border-radius-standard);
    border: ${props => {
        switch(props.variant){
            case 'outline-primary':
                return '1px solid var(--primary)';
            case 'outline-dotted-white':
                return '1px dotted white';
            default:
                return 'none';
        }
    }}
`

interface ButtonProp {
    children: any
    variant?: VariantsAvailable
}

export const Button : FC <ButtonProp>= ({ children, variant = "primary", ...rest }) => {
    return (
        <ButtonTag variant={variant} {...rest}>{children}</ButtonTag>
    )
}