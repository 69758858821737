import { FC } from "react"
import styled from 'styled-components'
import { devices } from "../../devices"

import { ProductType } from "../../types/products/products-type"

const ProductGridTag = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    @media(${devices.sm}){
        grid-template-columns: 1fr 1fr;
    }
    @media(${devices.md}){
        grid-template-columns: repeat(3, 1fr);
    }    
    @media(${devices.lg}){
        grid-template-columns: repeat(4, 1fr);
    }    
    gap: 10px;
`
type ProductGridProps = {
    products : Array<ProductType>,
    card: FC<{ product: ProductType }>
}

export const ProductGrid : FC<ProductGridProps> = ({ products, card }) => {
    return (
        <ProductGridTag>
            {
                products.map(
                    (product : ProductType) => card({ product })
                )
            }
        </ProductGridTag>
    )
}