import { FC } from "react";

import styled from 'styled-components'
import { FaSearch } from "react-icons/fa";

const SearchTag = styled.div`
    width: 400px;
    background-color: var(--gray);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding:10px;
    border-radius: var(--border-radius-max);    
`

const SearchInput = styled.input`
    outline: none;
    width: 100%;
    background-color: transparent;
    border: none;
    ::placeholder{
        color: #aaa;
    }
`
export const SearchBar : FC = () => (
    <SearchTag>
        <FaSearch size={25}/>
        <SearchInput placeholder="Search meals" />
    </SearchTag>
)