import { Splide, SplideSlide } from "@splidejs/react-splide";
import { FC } from "react";
import { deviceWidth } from "../../devices";

type InfiniteSliderProp = {
    slideItems: Array<any>,
    slideLayout: any
}

export const InfiniteSlider : FC<InfiniteSliderProp> = ({ slideItems, slideLayout }) => {

        return (
        <Splide options={{
            rewind: true,
            perPage: 1,
            mediaQuery: 'min',
            breakpoints: {
                [deviceWidth.sm]: {
                    perPage: 2,
                },
                [deviceWidth.md]: {
                    perPage: 3,
                },
                [deviceWidth.lg]: {
                    perPage: 4,
                },
                [deviceWidth.xl]: {
                    perPage: 5,
                }                


            },

            arrows: false,
            gap: '10px'
        }}>
            {
                slideItems.map(
                    slideItem => (
                        <SplideSlide role="section">
                            { slideLayout({slideData: slideItem})}
                        </SplideSlide>
                    )
                )
            }
        </Splide>
    )
}