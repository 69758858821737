import { FC } from "react";
import styled from 'styled-components'
import { Container } from "../../standard/Container";
import { NewsLetter } from "./NewsLetter";
import { AboutUs } from "./AboutUs";

const FooterTag = styled.div`
    margin-top: 40px;
    padding: 20px 10px;
    background-color: #24262b;
    color: white;
    box-sizing: border-box;
`

export const Footer : FC = () => {
    return (
        <FooterTag>
            <Container>
                <NewsLetter />
                <AboutUs />
            </Container>
        </FooterTag>
    )
}