import { FC } from "react";
import styled from 'styled-components'
import { devices } from "../../../devices";

const AboutUsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas: "yumeats yumeats"
    "others others"
    "others others";
    @media(${devices.lg}){
        grid-template-columns: repeat(4, minmax(0, 1fr));
        grid-template-rows: 1fr 1fr;
        grid-template-areas: "yumeats yumeats yumeats yumeats " 
        "others others others others";
    }    
    @media(${devices.xl}){
        grid-template-columns: 2fr repeat(4, minmax(0, 1fr));
        grid-template-rows: 1fr;
        grid-template-areas: "yumeats yumeats others others others others";
    }
    padding: 50px 0px;
    justify-items: center;
    color: #ccc;
    p {
        line-height: 1.5rem;
        padding-right: 20px;
    }
`

const YumEats = styled.span`
    display: block;
    font-size: 2rem;
    font-weight: 700;
    color: var(--primary);
`

const LocationList = styled.ul`
    padding: 0;
    padding-right: 10px;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 40px;

`
export const AboutUs : FC = () => {
    return (
        <AboutUsContainer>
            <div style={{ gridArea: 'yumeats' }}>
                <YumEats>YumEats</YumEats>
                <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum molestiae delectus culpa hic assumenda, voluptate reprehenderit dolore autem cum ullam sed odit perspiciatis. Doloribus quos velit, eveniet ex deserunt fuga? Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum molestiae delectus culpa hic assumenda, voluptate reprehenderit dolore autem cum ullam sed odit perspiciatis. Doloribus quos velit, eveniet ex deserunt fuga? Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum molestiae delectus culpa hic assumenda, voluptate reprehenderit dolore autem cum ullam sed odit perspiciatis. Doloribus quos velit, eveniet ex deserunt fuga?
                </p>
            </div>
            {
                Array.from({ length: 4 }, () => {
                    return (
                        <div>
                            <h4>Location</h4>
                            <LocationList>
                                <li>New York</li>
                                <li>USA</li>
                                <li>India</li>
                                <li>Canada</li>
                            </LocationList>
                        </div>
                    )
                })
            }

        </AboutUsContainer>
    )
}