import { useMainBannerImages } from "../../../hooks/use-main-banner-images"
import { Container } from "../../standard/Container"
import { Slider } from "../../standard/Slider"

export const MainSlider = () => {

    const { images } = useMainBannerImages()

    return (
        <Container>
            <Slider images={images} />
        </Container>
    )
}