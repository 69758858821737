import { FC } from "react";

import styled from 'styled-components'

import { Container } from "../../standard/Container";
import { Section } from "../../standard/Section";
import { SectionName } from "../../theme/SectionName";
import { Button } from "../../theme/Button";
import { devices } from "../../../devices";

const SectionContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    @media(${devices.md}){
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
    }
`

const ImageContainer = styled.div`
    width: 100%;
    text-align: center;
    img {
        width: 70%;
        margin: 0 auto;
    }
`

const InfoContainer = styled.div`
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    align-items: center;
    @media(${devices.md}){
        align-items: flex-start;
    }
`

export const DeliveryApp : FC = () => {
    return (
        <Container>
            <Section>
                <SectionName>Delivery App</SectionName>
                <SectionContainer>
                    <ImageContainer>
                        <img 
                            src="https://res.cloudinary.com/ehizeex-shop/image/upload/v1672676822/NetflixApp/FC_two_phones.6ec9a842f905769677f9_m91off.webp" 
                            alt="Delivery App"
                        />
                    </ImageContainer>
                    <InfoContainer>
                        <span style={{ color: "var(--secondary)", fontSize:"1.3rem", fontWeight: 700 }}>Get the app</span>
                        <h3 style={{ color: "var(--black)", fontSize: "2rem", margin: "16px 0"}}>Limitless Convenience on-demand</h3>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum molestiae delectus culpa hic assumenda, voluptate reprehenderit dolore autem cum ullam sed odit perspiciatis. Doloribus quos velit, eveniet ex deserunt fuga? Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum molestiae delectus culpa hic assumenda, voluptate reprehenderit dolore autem cum ullam sed odit perspiciatis. Doloribus quos velit, eveniet ex deserunt fuga? Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum molestiae delectus culpa hic assumenda, voluptate reprehenderit dolore autem cum ullam sed odit perspiciatis. Doloribus quos velit, eveniet ex deserunt fuga?
                        </p>
                        <Button variant="dark">Get started</Button>
                    </InfoContainer>
                </SectionContainer>
            </Section>
        </Container>
    )
}
