import { FC } from "react";

import { useNavigationItems } from "../../../hooks/use-navigation-items";

export const DrawerContent : FC<any> = ({ tileComponent }) => {

    const { navigationItems } = useNavigationItems()
    
    return (
        <ul>
            { 
                navigationItems.map(
                    ({ icon, text }) => (
                        <li>{ tileComponent({ icon, text })}</li>
                    )
                )
            }
        </ul>
    )
}