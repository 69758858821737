import { TopPicksType } from "../types/top-picks/top-pics-type";

export function useTopPicks() : Record<string, Array<TopPicksType>>{
    const topPicks : Array<TopPicksType>= [
        {
          id: 1,
          title: 'shawarma',
          price: '$850',
          img: 'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672323737/NetflixApp/chiken_shawarma_c0ysna.jpg'
        },
        {
          id: 2,
          title: 'fruite juice',
          price: '$900',
          img: 'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672329939/NetflixApp/fruite_juice_du7uqy.jpg',
        },
        {
          id: 3,
          title: 'yogurt parfaite',
          price: '$450',
          img: "https://res.cloudinary.com/ehizeex-shop/image/upload/v1672329944/NetflixApp/yogurt_parfait_kt8b5f.jpg"
        },
        {
          id: 4,
          title: 'orange juice',
          price: '$420',
          img: 'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672329938/NetflixApp/orangejuice_s4mdbi.webp'
        },
        {
          id: 5,
          title: 'egg & plantain',
          price: '$700',
          img: 'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672331290/NetflixApp/edd_and_plantain_rey7l4.jpg',
        },
        {
          id: 6,
          title: 'tilapia fish',
          price: '$900',
          img: 'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672331305/NetflixApp/tilapia_fish_g0ozkn.jpg'
        },
        {
          id: 7,
          title: 'jollof rice',
          price: '$150',
          img: 'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672332323/NetflixApp/jollof_rice_gbzosf.webp',
        },
        {
          id: 8,
          title: 'chiken burger',
          price: '$300',
          img: 'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672332323/NetflixApp/chiken_burger_ncsmeh.webp',
        },
        {
          id: 9,
          title: 'egg and plantain',
          price: '$500',
          img: 'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672331290/NetflixApp/edd_and_plantain_rey7l4.jpg',
        },
        {
          id: 10,
          title: 'African Salad',
          price: '$200',
          img: 'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672323870/NetflixApp/African_Salad_s7vgxn.png',
        },
        {
          id: 11,
          title: 'chinese pizza',
          price: '$900',
          img: 'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672324456/NetflixApp/chinese_pizza_kgnbo1.jpg',
        },
      ];

      return {
        topPicks        
      }
}