import { FC } from "react";
import styled from 'styled-components'

const LogoTag = styled.span`
    font-size: 2rem;
    font-weight: bold;
    color: var(--black);
    span {
        color: var(--primary);
    }
`

export const Logo : FC = () => {
    return (
        <LogoTag>Yum <span>Eats</span></LogoTag>
    )
}
