import { TrendingCategoryType } from "../types/products/trending-categories-type";

export function useTrendingCategories(){

    const categories : Array<TrendingCategoryType> = [
        {
          id: 1,
          name: 'Burger',
          image:
             'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672070207/NetflixApp/burger_logo_hmpqua.png'
        },
        {
          id: 2,
          name: 'veg',
          image:
            'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672070207/NetflixApp/veg_logo_jvm4qn.png'
        },
        {
          id: 3,
          name: 'Pizza',
          image:
            'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672070207/NetflixApp/pizza_logo_dqdqds.png',
        },
        {
          id: 4,
          name: 'Wings',
          image:
            'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672070208/NetflixApp/wings_logo_hrn8zl.png'
        },
    
        {
          id: 5,
          name: 'Best Overall',
          image:
            'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672070207/NetflixApp/fastfood_logo_v80nbk.png'
        },
        {
          id: 6,
          name: 'sharwama',
          image:
            'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672070207/NetflixApp/sharwama_logo_st4vg8.png'
        },
        {
          id: 7,
          name: 'resottoa',
          image:
            'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672176749/NetflixApp/resotto_logo_uywrr1.png'
        },
        {
          id: 8,
          name: 'cake',
          image:
            'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672176749/NetflixApp/cake_edpoec.png'
        },
        {
          id: 9,
          name: 'chiken',
          image:
            'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672176749/NetflixApp/chiken_logo_ahl2ca.png'
        },
        {
          id: 10,
          name: 'ice cream',
          image:
            'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672176749/NetflixApp/ice_cream_logo_hcqcaa.png'
        },
        {
          id: 11,
          name: 'beef',
          image:
           'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672176749/NetflixApp/beef_logo_adnidz.png'
        },
        {
          id: 12,
          name: 'apple pie',
          image:
            'https://res.cloudinary.com/ehizeex-shop/image/upload/v1672176749/NetflixApp/appple_pie_yxfxpm.png'
        },
      ];

     return {
        trendingCategories : categories
     }
}