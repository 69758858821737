import { FC } from 'react'

import styled from 'styled-components'
import { DrawerContent } from './DrawerContent'
import { DrawerTile } from "./DrawerTile";

import { Logo } from './Logo'

const DropBack = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 5;
    background-color: rgb( 0 0 0 / 0.5);
`

const DrawerTag = styled.div<{ isOpen: boolean}>`
    position: absolute;
    top: 0;
    left: ${props => props.isOpen ? 0 : '-100%'};
    height: 100%;
    width: 300px;
    z-index: 10;
    background-color: white;
    transition-duration: 500ms;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    box-sizing: border-box;
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: flex-start;
        align-items: flex-start;
    }
`

const CloseButtonTag = styled.span`
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    font-size: 1.5rem;
    color: var(--black);
    font-weight: 900;
`
interface PropType {
    isOpen: boolean,
    onClose: () => void
}

export const SideNav : FC<PropType> = ({ isOpen, onClose: closeSideNav }) => {
    return (
        <>
            { isOpen && (<DropBack onClick={() => closeSideNav()}/>) }
            <DrawerTag isOpen={isOpen} >
                <Logo />
                <DrawerContent tileComponent={DrawerTile} />
                <CloseButtonTag onClick={closeSideNav}>X</CloseButtonTag>
            </DrawerTag>
        </>
    )
}